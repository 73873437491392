// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import Dropzone from "dropzone";
import select2 from 'select2';

Dropzone.autoDiscover = false;

require("trix")
require("@rails/actiontext")
require('jquery')
require('popper.js')
require('bootstrap')

$(document).on('turbolinks:load', function() {
  if (document.getElementsByClassName('select2').length > 0) {
    new select2($('.select2'));

    $('.select_all').on('click', function(e) {
      e.stopPropagation()
      $("#notice_organization_ids > option").prop("selected", "selected");
      $("#notice_organization_ids").trigger("change");
    })
  }

  $('.dropdown-toggle').dropdown();

  if (document.getElementsByClassName('dropzone').length > 0) {
    new Dropzone('.dropzone', {
      paramName: 'files',
      uploadMultiple: true,
      parallelUploads: 5,
      maxFiles: 5,
      autoProcessQueue: false,
      maxFilesize: 512,
      addRemoveLinks: true,
      dictRemoveFile: 'Eliminar archivo',
      dictMaxFilesExceeded: 'Has excedido el máximo de archivos (5)',
      init: function() {
        var submitButton = document.querySelector("#submit-all")
        var myDropzone = this;

        submitButton.addEventListener("click", function() {
          myDropzone.processQueue();
        });

        this.on("addedfile", function() {
          $('#submit-all').removeClass('d-none')
        });

      },
      success: function(file, response){
        window.location.reload()
      }
    });
  }

  if (document.getElementsByClassName('show_password_icon').length > 0) {
    $('.show_password_icon').on('click', function() {
      var input = $('input.show_password_input').get(0);
      var iconTarget = document.getElementsByClassName('icon-target')[0]

      if (input.type == 'password') {
        input.type = 'text';

        iconTarget.classList.remove('fa-eye')
        iconTarget.classList.add('fa-eye-slash')
      } else {
        input.type = 'password';
        iconTarget.classList.remove('fa-eye-slash')
        iconTarget.classList.add('fa-eye')
      }
    })
  }
})
